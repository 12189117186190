/* eslint-disable max-len */
import { createSlice, createAsyncThunk, type PayloadAction } from '@reduxjs/toolkit';
import { setCurrentLanguage } from 'helpers/utils';
import { deleteDoc, updateDoc } from 'firebase/firestore';
import type { AnyUser } from 'types';
import { getUserInfo } from 'helpers/firebaseHelpers';
import { defaultDebugHandler } from 'helpers/errorHandlingHelper';
import { type EngagedProviderReference } from 'types/EngagedProviderReference';
import { defaultLocale } from '../../constants/defaultValues';

interface UserState {
  locale: string;
  loading: boolean;
  error: string | null;
  info: string | null;
  history: History | null;
  user: AnyUser | null;
}

const initialState: UserState = {
  locale: defaultLocale,
  loading: true,
  error: null,
  info: null,
  history: null,
  user: null,
};

interface UpdateUserProfileParams {
  fieldsToUpdate: Partial<AnyUser>;
  userId: string;
}

export const updateUserProfile = createAsyncThunk(
  'users/updateUserProfile',
  async ({ fieldsToUpdate, userId }: UpdateUserProfileParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);

      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }

      const updatedUser = {
        ...userInfo.data(),
        ...fieldsToUpdate,
      };
      await updateDoc(userInfo.ref, updatedUser);
      const userWithUid = { ...updatedUser, uid: userId };
      // TODO: update user in redux
      // setCurrentUserStatic(userWithUid as AnyUser);
      return { user: userWithUid, state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

interface UpdateEngagedProviderParams {
  providerToUpdate: Partial<EngagedProviderReference>;
  userId: string;
}

export const updateEngagedProvider = createAsyncThunk(
  'users/updateEngagedProvider',
  async ({ providerToUpdate, userId }: UpdateEngagedProviderParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);

      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }

      const userData = userInfo.data();
      const { myProviders } = userData;

      const updatedMyProviders = myProviders.map((provider: EngagedProviderReference) => {
        if (provider.id === providerToUpdate.id) {
          return { ...provider, ...providerToUpdate };
        }
        return provider;
      });

      const updatedUserData = {
        ...userData,
        myProviders: updatedMyProviders,
      };
      await updateDoc(userInfo.ref, updatedUserData);
      const userWithUid = { ...updatedUserData, uid: userId };
      // TODO: update user in redux
      return { user: userWithUid, state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

interface DeleteUserProfileParams {
  userId: string;
}

export const deleteUserProfile = createAsyncThunk(
  'users/deleteUserProfile',
  async ({ userId }: DeleteUserProfileParams, { rejectWithValue }) => {
    try {
      const userInfo = await getUserInfo(userId);
      if (userInfo === null) {
        return rejectWithValue({ error: 'User does not exist' });
      }
      await deleteDoc(userInfo.ref);
      // TODO: clear user from redux
      // setCurrentUserStatic(null);
      return { state: 'success' };
    } catch (error) {
      return rejectWithValue({ error: (error as Error).message });
    }
  },
);

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeLocale: (state, action: PayloadAction<string>) => {
      setCurrentLanguage(action.payload);
    },
    setUser: (state, action: PayloadAction<AnyUser | null>) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state, action) => {
        defaultDebugHandler('updateUserProfile.fulfilled');
        state.loading = false;
        state.info = 'Profile Updated';
        state.user = action.payload.user as AnyUser;
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      })
      .addCase(deleteUserProfile.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserProfile.fulfilled, (state) => {
        state.info = 'Profile Deleted';
        state.loading = false;
      })
      .addCase(deleteUserProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message ?? 'An error occurred';
      });
  },
});

export const { changeLocale, setUser } = userSlice.actions;
export default userSlice.reducer;
