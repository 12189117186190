import type { Ticket } from './Ticket';

export type ProviderTicketStatusValue = 'PENDING' | 'APPROVING' | 'COMPLETED';

export const ProviderTicketStatus: { [v in ProviderTicketStatusValue]: v } = {
  PENDING: 'PENDING',
  APPROVING: 'APPROVING',
  COMPLETED: 'COMPLETED',
};

// Tickets opened to ta provider from Homeowner? (what about HOA?)
export interface ProviderTicket extends Ticket {
  providerId: string;
  providerName: string;
  status: keyof typeof ProviderTicketStatus;
  extraInfo?: string;
  createDate: string;
}
