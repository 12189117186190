import type { Ticket } from './Ticket';
import { type TicketComment } from './TicketComment';

export const TicketStatus: Record<string, string> = {
  OPEN: 'OPEN',
  SOLVED: 'SOLVED',
  PENDING: 'PENDING',
  CLOSED: 'CLOSED',
  REFRESH: 'REFRESH',
};

// Tickets opened to a provider from a Homeowner
export interface HoaTicket extends Ticket {
  nameProviderHOA?: string;
  idProviderHOA?: string;
  comments?: TicketComment[];
  carpeta?: string;
  status: keyof typeof TicketStatus;
}
